import React from 'react';
import { Button } from 'semantic-ui-react';

import './Launch.css';
import clientConfig from './client.json';
import background from './content/VolvoVirtualShowroomSplashScreenMaster.jpg';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'Volvo Cars UK | Virtual Showroom';
  title?: string = 'Volvo Cars UK | Virtual Showroom';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  return (
    <div id="launchContainer" style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
      <div>
        <h1>{client.description}</h1>
        <Button size="massive" color="blue" circular icon="play" onClick={() => props.Launch()}></Button>
      </div>
	</div>
  );
};
