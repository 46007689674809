/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */
 
 import {
  LaunchStatusEvent,
  LaunchStatusType,
  ModelDefinition,
  PlatformNext,
  UndefinedModelDefinition,
  InputEmitter,
  DefaultStreamerOptions,
  StreamerStatus
} from '@pureweb/platform-sdk';

import {
  useStreamer,
  useLaunchRequest,
  IdleTimeout,
  LaunchRequestOptions,
  VideoStream,
  System
} from '@pureweb/platform-sdk-react';

import * as qs from 'query-string';
import React, { useEffect, useState, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button, Icon } from 'semantic-ui-react';
import useAsyncEffect from 'use-async-effect';
import './App.css';
import clientConfig from './client.json';

import { LaunchView } from './Launch';
import logger from './Log';

export interface HumanReadable {
  [propertyName: string]: string;
}

const humanReadableStatus: HumanReadable = {
  unknown: '(1/5) Initialising the Virtual Experience',
  accepted: '(2/5) Accepted, loading...',
  queued: '(3/5) Building the Virtual Environment..',
  requested: '(4/5) Experience loaded, launching...',
  ready: '(5/5) Ready, Entering Virtual Experience',
  serviced: 'Viewing model',
  cancelled: 'Cancelled',
  modelerror: 'An issue with the model has occurred',
  unavailable: 'The requested model does not exist'
}

/* ################################### OS FUNCTION ################################# */

			function getOS() {
			  var userAgent = window.navigator.userAgent,
				  platform = window.navigator.platform,
				  macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
				  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
				  iosPlatforms = ['iPhone', 'iPad', 'iPod'],
				  os = null;

			  if (macosPlatforms.indexOf(platform) !== -1) {
				os = 'Mac OS';
			  } else if (iosPlatforms.indexOf(platform) !== -1) {
				os = 'iOS';
			  } else if (windowsPlatforms.indexOf(platform) !== -1) {
				os = 'Windows';
			  } else if (/Android/.test(userAgent)) {
				os = 'Android';
			  } else if (!os && /Linux/.test(platform)) {
				os = 'Linux';
			  }

			  return os;
			} 
			

/* ################################################################################### */

const client: ClientJson = clientConfig as ClientJson;


class ClientJson {
  environmentId?: string;
  launchType?: string;
  projectId?: string;
  modelId?: string;
  version?: string;
  endpoint?: string;
  usePointerLock?: boolean;
  pointerLockRelease?: boolean;
  useNativeTouchEvents?: boolean;
}

class ClientOptions {
  // Overridable connection options
  LaunchType?: string;
  Collaboration?: boolean;
  
  // Launch queue configuration
  ProjectId?: string;
  ModelId?: string;
  Version?: string;
  EnvironmentId?: string;
  Endpoint?: string;

  // Overridable streamer options
  ForceRelay = false;
  UseNativeTouchEvents?: boolean;
  UsePointerLock?: boolean;
  PointerLockRelease?: boolean;

  isValid(): boolean {
    if (!this.ProjectId) {
      return false;
    }
    if (!this.ModelId) {
      return false;
    }
    return true;
  }
}

interface LoadingProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
}

const LoadingView: React.FC<LoadingProps> = (props: LoadingProps) => {
  if (props.StreamerStatus === StreamerStatus.Connected || props.StreamerStatus === StreamerStatus.Completed) {
    return <div />;
  }

  let content;

  if (props.StreamerStatus === StreamerStatus.NotSupported) {
    content = (
      <div>
        <h3>Your browser does not support the necessary WebRTC capabilities.</h3>
      </div>
    );
  }
  if (
    props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
    props.LaunchRequestStatus.status === LaunchStatusType.Error ||
    props.StreamerStatus === StreamerStatus.Failed
  ) {
    content = (
      <div>
        <h3>The experience is presently unavailable.</h3>
        <h3>Please refresh to request a new session.</h3>
      </div>
    );
  } else {		
    content = (
	
      <div>
        <img src="./VolvoLogoSpin_final.gif" alt="Volvo Loading Logo" width="150" height="150"></img>
			<h3>{humanReadableStatus[props.LaunchRequestStatus.status]}</h3>
      </div>
    );
  } return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <div style={{ textAlign: 'center' }}>{content}</div>
    </div>
  );
};

interface ViewProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
  VideoStream: MediaStream;
  InputEmitter: InputEmitter;
  UseNativeTouchEvents: boolean;
  UsePointerLock: boolean;
  PointerLockRelease: boolean;
}

const EmbeddedView: React.FC<ViewProps> = (props: ViewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const handle = useFullScreenHandle();
  // Fullscreen API presently supported on iPad, but not iPhone or iPod
  const isIPhone = System.Browser().os === 'iOS' && !window.navigator.userAgent.includes('iPad');
  
  //CustomFunctionEvents
  function hidePopup() {
        var popupContainer = document.getElementById("popup-container");
        if (popupContainer) {
            popupContainer.style.display = "none";
        }
    }


    function confirmEvent() {
        hidePopup();
        props.InputEmitter.EmitUIInteraction("ConfirmButtonEvent");
    }

 

    function cancelEvent() {
        hidePopup();
        props.InputEmitter.EmitUIInteraction("CancelButtonEvent");
    }
  
/* ################################### Collaboration Button Function ################################# */

/*
function getCollabURL()
  {
    //only create a URL if we have a valid environment
    if (platform.credentials.agent.environmentId) {        
        //create the collaborator URL
      var collabUrl = 'https://volvoukevents.co.uk/test' + '?environmentId=' + platform.credentials.agent.environmentId + '&collaboration=true';
		//prompt("Copy URL", collabUrl);
		  
		  navigator.clipboard.writeText(collabUrl);
		   		alert("Collaboration link copied to the clipboard. Send the link to the client.");

    };
  }*/
  
  
/* ################################### SEND MESSAGE FUNCTION ################################# */
function sendmessage(){
 	//send a message to the game about the light
	props.InputEmitter.EmitUIInteraction("changeLightState");
}

	props.InputEmitter.EmitUIInteraction({"Platform":getOS()}); 

function sendplatform(){
 	//send a message to the game about the light
	props.InputEmitter.EmitUIInteraction({"Platform":getOS()});
}


  
  return (
    <div style={{ height: '100%' }}>
      <FullScreen handle={handle}>
        <IdleTimeout
          Status={props.StreamerStatus}
          WarningThreshold={300}
          ExitThreshold={120}
          WarningCallback={handle.exit}
          ExitCallback={() => window.location.reload()} // TODO: How to 'close' a contribution?
        />

        <LoadingView LaunchRequestStatus={props.LaunchRequestStatus} StreamerStatus={props.StreamerStatus} />
        <VideoStream
          VideoRef={videoRef}
          Emitter={props.InputEmitter}
          Stream={props.VideoStream}
          UseNativeTouchEvents={props.UseNativeTouchEvents}
          UsePointerLock={props.UsePointerLock}
          PointerLockRelease={props.PointerLockRelease}
        />

 /* FULLSCREEN BUTTON ################################################################# */
        <Button
          onClick={handle.enter}
          style={{ position: 'absolute', top: 10, right: 10 }}
          className={isIPhone || handle.active || props.StreamerStatus !== StreamerStatus.Connected ? 'hidden' : ''}>
          <Icon name="expand" />
        </Button>
		
		<div className="popup" id="popup-container">
			<div className="popup-content">
				<h4 className="popup-header" id="header-text">This website will like to redirect you.</h4>
				<Button className="ui blue button confirm-button" onClick={confirmEvent} id="redirect-button" href="https://www.volvocars.com/uk/" target="_blank">Confirm</Button>
				<Button className="cancel-button" onClick={cancelEvent}>Cancel</Button>
			</div>
		</div>
		
        {props.StreamerStatus !== StreamerStatus.Connected && (
          <img
            alt=""
            src=""
            style={{ width: 100, position: 'absolute', bottom: 50, right: 10 }}
          />
        )}
      </FullScreen>
    </div>
  );
};

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio();
audio.autoplay = true;
audio.volume = 0.5;

// Parse query parameters
const query = qs.parse(window.location.search);
const clientOptions: ClientOptions = new ClientOptions();
clientOptions.LaunchType = (query['launchType'] as string) ?? client.launchType;

if( query['collaboration'] &&  query['collaboration'] == 'true')  
  clientOptions.Collaboration = true;
else
clientOptions.Collaboration = false;

clientOptions.Endpoint = (query['endpoint'] as string) ?? client.endpoint;
clientOptions.ProjectId = (query['projectId'] as string) ?? client.projectId;
clientOptions.ModelId = (query['modelId'] as string) ?? client.modelId;
clientOptions.Version = (query['version'] as string) ?? client.version;
clientOptions.EnvironmentId = (query['environmentId'] as string) ?? client.environmentId;
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
  (query['usePointerLock'] === undefined ? client.usePointerLock : query['usePointerLock'] === 'true') ?? true;
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
  (query['pointerLockRelease'] === undefined ? client.pointerLockRelease : query['pointerLockRelease'] === 'true') ??
  true;

clientOptions.ForceRelay = query['forceRelay'] !== undefined ?? false;
clientOptions.UseNativeTouchEvents =
  (query['useNativeTouchEvents'] === undefined
    ? client.useNativeTouchEvents
    : query['useNativeTouchEvents'] === 'true') ?? false;
// Initialize platform reference
const platform = new PlatformNext();
platform.initialize({ endpoint: clientOptions.Endpoint || 'https://api.pureweb.io' });

const App: React.FC = () => {
  const [modelDefinitionUnavailable, setModelDefinitionUnavailable] = useState(false);
  const [modelDefinition, setModelDefinition] = useState(new UndefinedModelDefinition());
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const [launchRequestError, setLaunchRequestError] = useState<Error>();
  const streamerOptions = DefaultStreamerOptions;

  useAsyncEffect(async () => {
    if (clientOptions.ProjectId) {
      logger.info('Initializing available models: ' + clientOptions.ProjectId);
      try {
        await platform.useAnonymousCredentials(clientOptions.ProjectId, clientOptions.EnvironmentId);
        await platform.connect();
        logger.info('Agent Connected: ' + platform.agent.id);
        streamerOptions.iceServers = platform.agent.serviceCredentials.iceServers as RTCIceServer[];
        streamerOptions.forceRelay = clientOptions.ForceRelay;
        const models = await platform.getModels();
        setAvailableModels(models);
        logger.debug('Available models', models);
      } catch (err) {
        logger.error(err);
      }
    }
  }, [clientOptions]);

  useEffect(() => {
    if (availableModels?.length) {
      const selectedModels = availableModels.filter(function (model: ModelDefinition): boolean {
        if (clientOptions.ModelId === model.id) {
          // If there is a version specified and we encounter it
          if (clientOptions.Version && clientOptions.Version === model.version) {
            return true;
          }
          // If there is no version specified and we find the primary version
          if (!clientOptions.Version && model.active) {
            return true;
          }
        }
        return false;
      });
      if (selectedModels?.length) {
        setModelDefinition(selectedModels[0]);
      } else {
        setModelDefinitionUnavailable(true);
      }
    }
  }, [availableModels]);

  const launchRequestOptions: LaunchRequestOptions = {
    regionOverride: query['regionOverride'] as string,
    virtualizationProviderOverride: query['virtualizationProviderOverride'] as string
  };
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(platform, modelDefinition, launchRequestOptions);
  const [streamerStatus, emitter, videoStream, audioStream, messageSubject] = useStreamer(
    platform,
    launchRequest,
    streamerOptions
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect();
    }
  }, [streamerStatus]);

  if (audioStream) {
    audio.srcObject = audioStream;
  }

  const launch = async () => {
    setLoading(true);
    audio.load();

	if (clientOptions.LaunchType !== 'local' && !clientOptions.Collaboration ) {
		console.log("creating new session"); 
      try {
        await queueLaunchRequest();
      } catch (err) {
        setLaunchRequestError(err);
      }
    }
  };

  // Log status messages
  useEffect(() => {
    logger.info('Status', status, streamerStatus);
  }, [status, streamerStatus]);

  // Subscribe to game messages
  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value: string) => {
        logger.info('Message: ' + value);
		
// #################################### CODE TO RECEIVE INFROM FROM THE GAME #############################################
		
		// Parse the received messages
                const message = JSON.parse(value);
                var popupContainer = document.getElementById("popup-container");
                var header = document.getElementById("header-text");
                // Check if our "alert" message is inside and do something with it
                if (message.hasOwnProperty("SupportURL")) {
                    console.log(message.SupportURL);
                    if (popupContainer) {
                        document.getElementById("redirect-button")?.setAttribute("href", message.SupportURL);
                        popupContainer.style.display = "flex";
                    }
                    //var windowConfirmed = window.open(message.SupportURL, '_blank')!.focus();
                }
                else if (message.hasOwnProperty("SubscriptionURL")) {
                    console.log(message.SubscriptionURL);
                    if (popupContainer) {
                        document.getElementById("redirect-button")?.setAttribute("href", message.SubscriptionURL);
                        if (header) {
                            header.innerText = "To find out more about the cost & finance options on your chosen model, simply click 'continue' to visit volvocars.com/uk.";
                        }
                        popupContainer.style.display = "flex";
                    }
                }
                else if (message.hasOwnProperty("CallbackURL")) {
                    console.log(message.CallbackURL);
                    if (popupContainer) {
                        document.getElementById("redirect-button")?.setAttribute("href", message.CallbackURL);
                        if (header) {
                            header.innerText = "To speak to one of our experts, simply click 'continue' to complete a short form and they'll call you back.";
                        }
                        popupContainer.style.display = "flex";
                    }
                }
                else if (message.hasOwnProperty("TestDriveURL")) {
                    console.log(message.TestDriveURL);
                    if (popupContainer) {
                        document.getElementById("redirect-button")?.setAttribute("href", message.TestDriveURL);
                        if (header) {
                            header.innerText = "To book a test drive at your local Volvo Retailer, simply click 'continue' below to book a date and time that's best for you.";
                        }
                        popupContainer.style.display = "flex";
                    }
                }
                else if (message.hasOwnProperty("InfoXC40URL")) {
                    console.log(message.InfoXC40URL);
                    if (popupContainer) {
                        document.getElementById("redirect-button")?.setAttribute("href", message.InfoXC40URL);
                        if (header) {
                            header.innerText = "To find out more about the pure electric XC40 Recharge, simply click 'continue' below to visit volvocars.com/uk.";
                        }
                        popupContainer.style.display = "flex";
                    }
                }
                else if (message.hasOwnProperty("InfoC40URL")) {
                    console.log(message.InfoC40URL);
                    if (popupContainer) {
                        document.getElementById("redirect-button")?.setAttribute("href", message.InfoC40URL);
                        if (header) {
                            header.innerText = "To find out more about the pure electric C40 Recharge, simply click 'continue' below to visit volvocars.com/uk.";
                        }
                        popupContainer.style.display = "flex";
                    }
                }

// #####################################################################################		
		
      },
      (err) => {
        logger.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject]);

  // Notify user of missing or errors in configuration
  if (!clientOptions.isValid()) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <p>
          Your client has one or more configuration errors. Please consult the{' '}
          <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client"> README </a> for details on how
          to configure the client template.
        </p>
      </div>
    );
  }

  if (modelDefinitionUnavailable) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <span>The model that you have requested does not exist</span>
      </div>
    );
  }

  if (launchRequestError) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <span>
          {process.env.NODE_ENV === 'development'
            ? `There was an error with the launch request: ${launchRequestError}`
            : 'It appears the requested model is currently not online as per your set schedule. Please contact support if it should be available.'}
        </span>
      </div>
    );
  }

  // Begin connection
  if (streamerStatus === StreamerStatus.Disconnected) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Disconnected from stream</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Failed) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Failure during stream</h2>
        <h2>Please refresh to request a new session</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Streamer contribution withdrawn</h2>
      </div>
    );
  }

  if (loading) {
    return (
      <EmbeddedView
        VideoStream={videoStream}
        StreamerStatus={streamerStatus as StreamerStatus}
        LaunchRequestStatus={status}
        InputEmitter={emitter}
        UseNativeTouchEvents={clientOptions.UseNativeTouchEvents!}
        UsePointerLock={clientOptions.UsePointerLock!}
        PointerLockRelease={clientOptions.PointerLockRelease!}
      />
    );
  } else if (clientOptions.LaunchType !== 'local' && !availableModels) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Initializing...</h2>
      </div>
    );
  } else if (clientOptions.LaunchType !== 'local' && !availableModels?.length) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>No models are currently available in this environment.</h2>
      </div>
    );
  } else {
    return <LaunchView Launch={launch} />;
  }
};

const AppWrapper: React.FC = () => {
  return true ? (
    <App />
  ) : (
    <div className="ui red segment center aligned basic">
      <h2 className="header">Your browser is currently unsupported</h2>
    </div>
  );
};


export default AppWrapper;

